<template>
    <div>
        <div class="msg-header">消息</div>
        <div class="msg-content">
            <div class="msg-content__overview">
                <div class="msg-content__overview__left">全部消息{{totleMessage}}条</div>
                <div class="msg-content__overview__right"><span style="margin-right: 10px;">未读{{noMessage}}条</span><span @click="allToTead">标记为已读</span></div>
            </div>
            <div class="msg-content__list">
                <!-- <div class="msg-content__list__item round-box">
                    <div class="list-item__header">
                        <div class="list-item__header__left">调试额定油耗超标报警</div>
                        <div class="list-item__header__right">2小时前</div>
                    </div>
                    <div class="list-item__content">设备21BC52305238调试油耗348L，触发报警条件，请您关注现场情况！</div>
                </div>
                <div class="msg-content__list__item round-box">
                    <div class="list-item__header">
                        <div class="list-item__header__left">调试参数合规率报警</div>
                        <div class="list-item__header__right">2小时前</div>
                    </div>
                    <div class="list-item__content">设备21BC52305238参数-发动机转速判断不合规，触发报警条件，请您关注现场情况！</div>
                </div> -->

                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div class="msg-content__list__item round-box"  v-for="(item, i) in dataList" :key="i" @click="toDeviceInfo(item)">
                        <div class="list-item__header">
                            <div class="list-item__header__left">{{item.msgRuleTypeContent}} <span class="dianShow" v-if="item.readFlag == 0"></span></div>
                            <div class="list-item__header__right">{{item.timeDiff}}</div>
                        </div>
                        <div class="list-item__content">{{item.msgContent}}</div>
                    </div>
                </van-list> 
            </div>
        </div>
    </div>
</template>

<script>
import { getWarnMessageList,getNoMessageNum,setReadMessage,setReadAllMessage } from '@/axios/message'
import {getTimeAgo} from '@/utils/common'
export default({
    data() {
        return{
            loading: false,
            finished: false,
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            totleMessage: 0,
            noMessage: 0,
            dataList: [],
            userAccount: '',
        } 
    },
    mounted(){
        
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        this.userAccount = userInfo.loginCode
        this.getNoticeList()
        this.getNoMessageNumFun()
    },
    methods: {
        onLoad(){
            this.page.pageNo++
            this.getNoticeList()
        },
        getNoticeList(){
            getWarnMessageList({
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize,
                userAccount: this.userAccount
            }).then(res=>{
                console.log(res)
                let list = this.dataList.concat(res.content.records || [])
                for(let i=0;i<list.length;i++){
                    list[i].timeAgo = getTimeAgo(Date.parse(new Date(list[i].createDate))/1000)
                }
                this.dataList = list
                this.totleMessage = res.content.total
                this.page.total = res.content.total
                this.finished = this.dataList.length >= this.page.total
            }).finally(()=>{                
                this.loading = false
            })
        },
        getNoMessageNumFun(){
            getNoMessageNum({userAccount: this.userAccount}).then(res=>{
                this.noMessage = res.content
            })
        },
        toDeviceInfo(info){
            setReadMessage({msgId:info.msgId}).then(res=>{
                if(res.code == 200){
                    this.$router.push({path: `/home/deviceDetail/${info.equipCode}`})
                }
            }) 
        },
        allToTead(){
            setReadAllMessage({userAccount: this.userAccount}).then(res=>{
                if(res.code == 200){
                    this.page.pageNo = 1
                    this.dataList = []
                    this.getNoticeList()
                    this.getNoMessageNumFun()
                }
            })
        }
    }
})
</script>


<style lang="scss" scoped>
    .msg-header{
        background: #3f83f8;
        color: #fff;
        font-size: 17px;
        height: 46px;
        line-height: 46px;
    }
    .msg-content{
        background: #f2f2f2;
        .msg-content__overview{
            margin: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .msg-content__overview__left{
                font-size: 17px;
                color: #595959;
                font-weight: bold;
            }
            .msg-content__overview__right{
                font-size: 14px;
                color: #3f83f8;
            }
        }
    }
    .list-item__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        .list-item__header__left{
            color: #333333;
            font-size: 17px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: left;
        }
        .list-item__header__right{
            color: #8c8c8c;
            font-size: 12px;
        }
    }
    .list-item__content{
        color: #8c8c8c;
        font-size: 14px;
        text-align: left;
        margin-top: 5px;
    }
    .dianShow{
        background-color: #F05252;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 10px;
    }
</style>
